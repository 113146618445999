import { Link, graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import { Seo } from "../components/seo"

export default function Page({ data: { markdownRemark: post }, location }) {
  return (
    <Layout location={location}>
      <article>
        <header>
          <h2 className="page-title">
            <Link to={post.fields.slug}>{post.frontmatter.title}</Link>
          </h2>
        </header>
        <section dangerouslySetInnerHTML={{ __html: post.html }} />
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`

export const Head = ({
  data: {
    markdownRemark: post,
    site: { siteMetadata },
  },
}) => <Seo title={post.frontmatter.title + " | " + siteMetadata.title} />
